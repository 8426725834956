/**
* CustomerEntityHelper.ts (abstractuser) *

* Copyright © 2024 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2024
* @file CustomerEntityHelper.ts
* @author Rafael Rodrigues
* @copyright 2024 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import CryptoJS from 'crypto-js';

/**
 * Use this file to centralize all helpers related to the Customer entity.
 * @module CustomerEntityHelper
 */
export class CustomerEntityHelper {
  /**
   * Get hashed customer email
   * @param {string} customerEmail Defines the customer email to be hashed
   * @returns {string}
  */
  public static getHashedCustomerEmail(customerEmail: string): string { 
    return CryptoJS.SHA256(customerEmail).toString(
      CryptoJS.enc.Hex
    ); 
  }
}